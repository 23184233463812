import { Observable, Observer, of, combineLatest } from "rxjs";
import { switchMap, map, tap } from "rxjs/operators";

import utils, { observeVal } from "poola-commons/db";
import { Group } from "poola-commons/types";

import { firebaseApp } from "./firebase";

const user$: Observable<User> = Observable.create(
    (observer: Observer<FirebaseUser | null>) =>
        firebaseApp.auth().onAuthStateChanged(user => {
            if (user) {
                const { displayName, email, uid } = user;
                observer.next({
                    name: displayName || "-",
                    email: email || "-",
                    uid
                });
            } else {
                observer.next(null)
            }
        })
).pipe(
    switchMap((user: FirebaseUser | null) =>
        user
            ? combineLatest(
                  currentGroup$(user.uid),
                  userGroups$(user.uid)
              ).pipe(
                  map(([currentGroup, groups]) => ({
                      ...user,
                      currentGroup,
                      groups
                  })),
                  switchMap(user =>
                      user.currentGroup
                          ? userInGroup$({
                                group: user.currentGroup,
                                uid: user.uid
                            }).pipe(
                                map((userInGroup: any) => ({
                                    ...user,
                                    ...userInGroup
                                }))
                            )
                          : of(user)
                  )
              )
            : of(null)
    )
);

const groupMeta$ = (group: string): Observable<Group | null> =>
    observeVal<Group | null>(utils.ref().child(`grp/${group}/meta`)).pipe(
        map(groupData => (groupData && { id: group, ...groupData }) || null)
    );

const currentGroup$ = (uid: string): Observable<string | undefined> =>
    observeVal(utils.ref().child(`usr/${uid}/currentGroup`)).pipe(
        tap(console.log)
    );

const userGroups$ = (uid: string): Observable<string[]> =>
    observeVal<string[] | undefined>(utils.ref().child(`usr/${uid}/groups`))
        .pipe(map(groups => groups || []))
        .pipe(tap(console.log));

const userInGroup$ = ({ group, uid }: { group: string; uid: string }) =>
    observeVal<{ name: string; role: string } | undefined>(
        utils.ref().child(`grp/${group}/users/${uid}`)
    );

const signIn = (email: string, password: string) =>
    firebaseApp.auth().signInWithEmailAndPassword(email, password);

const signOut = () => firebaseApp.auth().signOut();

export type FirebaseUser = { uid: string; name: string; email: string };

export type User = FirebaseUser & {
    currentGroup: string | undefined;
    groups: string[];
    role: string;
    name: string;
};

export type GroupAdmin = {
    uid: string;
    name: string;
    email: string;
    group: any;
};

export { signIn, signOut, user$, groupMeta$ };
