import * as firebase from "firebase";
import poolaFirebase from "poola-commons/db";

var firebaseProduction = {
    apiKey: "AIzaSyCTrkqIkfYQmZArbjIMbmRDdURG60yyH0w",
    authDomain: "company-carpool.firebaseapp.com",
    databaseURL: "https://company-carpool.firebaseio.com",
    projectId: "company-carpool",
    storageBucket: "company-carpool.appspot.com",
    messagingSenderId: "933294010198",
    appId: "1:933294010198:web:4258944872d2822a7030f7",
    measurementId: "G-DWS4GJTVDJ"
};

const firebaseApp = firebase.initializeApp(firebaseProduction);
poolaFirebase.init(firebaseApp.database() as any);

export { firebaseApp };
