import React, { FunctionComponent } from "react";
import { TransitionablePortal, Modal } from "semantic-ui-react";

type Props = { show: boolean };

const Overlay: FunctionComponent<Props> = ({ show, children }) => {
  return (
    <TransitionablePortal
      open={show}
      transition={{ animation: "scale", duration: 300 }}
    >
      <Modal open={show} dimmer="inverted" size="mini">
        {children}
      </Modal>
    </TransitionablePortal>
  );
};

export default Overlay;
