import React, { FunctionComponent, useState } from "react";
import { Button, Form, List } from "semantic-ui-react";
import FileReaderInput from "react-file-reader-input";

import { inviteUsers } from "../services/group";
import { csvToArray } from "../utils/csv";
import { audit } from "rxjs/operators";

type Props = {
  group: string;
};

type User = {
  name: string;
  email: string;
};

const AddMultipleUsers: FunctionComponent<Props> = ({ group }) => {
  const [users, setUsers] = useState<User[]>();
  const [error, setError] = useState<string>();

  const [progress, setProgress] = useState<boolean>(false);

  const _onAddUsersPress = (users: User[]) => {
    setProgress(true);
    inviteUsers({ group, users })
      .catch(() => {
        setError("Error sending to server");
        setUsers(undefined);
      })
      .then(() => {
        setUsers(undefined);
        setProgress(false);
      });
  };

  const handleChange = (e: any, results: any[]) => {
    setError(undefined);
    setUsers(undefined);
    try {
      const usersCSV = results[0][0].target.result;
      const csvAsArray = csvToArray(usersCSV);
      const parsedCsv = csvAsArray
        .filter(([name, email]) => name && email)
        .map(([name, email, registration, externalId]) => ({
          name: name.trim(),
          email: email.trim(),
          registration: registration && registration.trim(),
          externalId: externalId && externalId.trim(),
        }));
      if (parsedCsv.length > 0) {
        setUsers(parsedCsv);
      } else {
        setError("Error parsing file");
      }
    } catch (e) {
      setError("Error uploading file");
    }
  };

  return (
    <form>
      {!users && (
        <FileReaderInput as="text" onChange={handleChange}>
          <p>
            To add multiple users at once, please upload a CSV file in following
            format. Column 1 - user's name, column 2 - email.
          </p>
          <p>
            Before adding is performed you will have a chance to review the
            list.
          </p>
          <Button
            color="teal"
            fluid
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Select CSV file
          </Button>
        </FileReaderInput>
      )}
      {users && (
        <>
          <List as={"div"} style={{ overflow: "auto", maxHeight: "200px" }}>
            {users.map(({ name, email }) => (
              <List.Item>
                <strong>{name}</strong>, {email}
              </List.Item>
            ))}
          </List>
          <Button
            color="teal"
            fluid
            onClick={() => _onAddUsersPress(users)}
            disabled={progress}
          >
            Add and Invite ({users.length})
          </Button>
        </>
      )}
    </form>
  );
};

export default AddMultipleUsers;
