import React, { FunctionComponent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Divider, Header, Modal, Button, Icon } from "semantic-ui-react";

import { Group, Map } from "poola-commons/types";

import MapView from "../map/MapView";
import { getGroup } from "../redux/store";
import { groupUsers$, LocatedUser, removeUser } from "../services/group";
import UsersList from "../components/UsersList";

import AddUsers from "./AddUsers";
import AddMultipleUsers from "./AddMultipleUsers";
import { map } from "lodash";
import ColorMarker from "../map/ColorMarker";
import Overlay from "../components/Overlay";
import { is } from "poola-commons";
import { useProgress } from "../hooks/useProgress";

const REGULAR_COLOR = "#b5cc18";
const FOCUSED_COLOR = "#e67c7c";

const UsersView: FunctionComponent<{}> = () => {
  const group: Group = useSelector<any, Group | undefined>(getGroup) as any;
  const [users, setUsers] = useState<Map<LocatedUser>>();
  const [selected, setSelected] = useState<string>();

  const [userToRemove, setUserToRemove] = useState<string>();

  const groupId = group && group.id;
  useEffect(() => {
    const subscription = groupUsers$(groupId).subscribe((newUsers) =>
      setUsers(newUsers)
    );
    return () => subscription.unsubscribe();
  }, [groupId]);

  const onUserRemoveConfirm = () =>
    userToRemove
      ? removeUser({ uid: userToRemove, group: groupId }).then(() =>
          setUserToRemove(undefined)
        )
      : Promise.reject();

  return group ? (
    <>
      <div
        style={{
          zIndex: 500,
          position: "fixed",
          bottom: "12px",
          right: "8px",
        }}
      >
        <Card>
          <Card.Content
            style={{
              maxWidth: "300px",
              maxHeight: "750px",
              height: "650px",
              overflow: "auto",
            }}
          >
            <Header as="h4">Add single user</Header>
            <AddUsers group={groupId} />
            <Divider />
            <Header as="h4">Add multiple users</Header>
            <AddMultipleUsers group={groupId} />
            <Divider />
            <Header as="h4">Users</Header>
            <UsersList
              onUserPress={setSelected}
              users={users || {}}
              selected={selected}
              onUserRemove={setUserToRemove}
            />
          </Card.Content>
        </Card>
      </div>
      <UserRemoveDialog
        uid={userToRemove}
        users={users}
        onRemovePress={onUserRemoveConfirm}
        onDontRemovePress={() => setUserToRemove(undefined)}
      />
      <MapView focus={group.location} zoom={13}>
        {map(users, ({ location }, uid) => {
          return (
            location && (
              <ColorMarker
                color={uid === selected ? FOCUSED_COLOR : REGULAR_COLOR}
                position={location}
                onclick={() => {
                  setSelected(uid);
                  return true;
                }}
              ></ColorMarker>
            )
          );
        })}
      </MapView>
    </>
  ) : null;
};

type DialogProps = {
  uid?: string;
  users?: Map<LocatedUser>;
  onRemovePress: () => Promise<void>;
  onDontRemovePress: () => void;
};

const UserRemoveDialog: FunctionComponent<DialogProps> = ({
  uid,
  users = {},
  onRemovePress,
  onDontRemovePress,
}) => {
  const user = uid && users[uid];
  const [onConfirmButtonClick, _result, progress] = useProgress(onRemovePress);

  return (
    <Overlay show={is(user)}>
      <Modal.Header>Remove {(user && user.name) || ""}?</Modal.Header>
      <Modal.Actions>
        <Button compact={true} color="pink" onClick={onDontRemovePress}>
          No
        </Button>
        <Button compact={true} color="teal" onClick={onConfirmButtonClick}>
          {progress && <Icon loading name="sync" />}
          Yes
        </Button>
      </Modal.Actions>
    </Overlay>
  );
};

export default UsersView;
