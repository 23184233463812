import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "semantic-ui-react";

import { user$ } from "../services/auth";
import { signOut } from "../services/auth";

const MenuComponent: FunctionComponent = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const history = useHistory();
  
  useEffect(() => {
    const { unsubscribe } = user$.subscribe((user) => {
      const authenticated = !!user;
      setLoggedIn(authenticated);
      if (!authenticated) {
        history.push("/login");
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <Menu>
      {loggedIn && (
        <Menu.Item active={false} onClick={signOut}>
          Sign out
        </Menu.Item>
      )}
    </Menu>
  );
};
export default MenuComponent;
