import { BrowserRouter, Switch, Route } from "react-router-dom";
import React from "react";
import { Provider } from "react-redux";

import "./App.css";

import { store } from "./redux/store";
import LoginView from "./views/Login";
import UsersView from "./views/Users";
import MenuComponent from "./components/Menu";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div
          style={{
            zIndex: 10000,
            position: "absolute",
            top: 12,
            right: 8,
          }}
        >
          <MenuComponent />
        </div>
        <Switch>
          <Route exact path="/">
            {/* lol */}
            {/* <MapView /> */}
          </Route>
          <Route path="/login">
            <LoginView />
          </Route>
          <Route path="/users">
            <UsersView />
          </Route>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
