import React, { FunctionComponent, useState } from "react";
import { Button, Form } from "semantic-ui-react";

import { inviteUsers } from "../services/group";

type Props = {
  group: string;
};

const AddUsers: FunctionComponent<Props> = ({ group }) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [progress, setProgress] = useState<boolean>(false);

  const _onAddUserPress = () => {
    setProgress(true);
    inviteUsers({ group, users: [{ email, name }] })
      .catch(console.log)
      .then(() => setProgress(false));
  };

  const notFilled = name.trim().length < 2 || email.trim().length < 6;

  return (
    <Form>
      <Form.Field>
        <label>Username</label>
        <input
          value={name}
          placeholder="Username"
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Email address</label>
        <input
          value={email}
          placeholder="Email address"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Field>
      <Button
        type="submit"
        color="teal"
        fluid
        onClick={_onAddUserPress}
        disabled={progress || notFilled}
      >
        Add and Invite
      </Button>
    </Form>
  );
};

export default AddUsers;
