import React from "react";

import {
  Form,
  Button,
  Grid,
  Header,
  Image,
  Segment,
  Message,
} from "semantic-ui-react";
import { FunctionComponent } from "react";

type Props = {
  email?: string;
  password?: string;
  error?: string;
  setEmail: (email: string) => void;
  setPassword: (email: string) => void;
  onLoginTap: () => void;
};

const Login: FunctionComponent<Props> = ({
  email,
  password,
  error,
  setEmail,
  setPassword,
  onLoginTap,
}) => {
  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 448 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Image src="/logo1024.png" /> Poola administration
        </Header>
        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button color="teal" fluid size="large" onClick={onLoginTap}>
              Login
            </Button>
          </Segment>
        </Form>
        {error && <Message error>{error}</Message>}
        <Message>
          Don't have an account yet? Contact us:{" "}
          <a href="mailto:hello@poola.app">hello@poola.app</a>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default Login;
