import { of } from "rxjs";
import { combineReducers, createStore, applyMiddleware } from "redux";
import { createSelector } from "reselect";
import {
  ActionsObservable,
  combineEpics,
  createEpicMiddleware,
} from "redux-observable";
import { switchMap, map, tap } from "rxjs/operators";
import reduce from "lodash/reduce";

import { Group, Habits, Map, UserInGroup } from "poola-commons/types";
import { usersHabitsInGroup$ } from "poola-commons/db";

import { groupUsers$, groupUsers2$ } from "../services/group";
import { user$, groupMeta$, User } from "../services/auth";
import { UserWithHabits } from "./d";
type Action<T> = {
  type: string;
  data: T;
};

// Actions
const SET_USER = "setUser";
const SET_GROUP_META = "setGroupMeta";
const SET_GROUP_USERS = "setGroupUsers";
const SET_GROUP_USERS_HABITS = "setGroupUsersHabits";

const user = (state = null, action: Action<User | null>) => {
  return action.type === SET_USER ? action.data : state;
};

const groupMeta = (state = null, action: Action<Group | null>) => {
  return action.type === SET_GROUP_META ? action.data : state;
};

const groupUsers = (
  state = null,
  action: Action<Map<{ role: string; name: string }>>
) => {
  return action.type === SET_GROUP_USERS ? action.data : state;
};

const groupUsersHabits = (state = null, action: Action<null>) => {
  return action.type === SET_GROUP_USERS_HABITS ? action.data : state;
};

const observeGroupUsersHabitsEpic = (
  action$: ActionsObservable<Action<User | null>>
) =>
  action$.ofType(SET_USER).pipe(
    switchMap(({ data }) => {
      return data && data.currentGroup
        ? usersHabitsInGroup$(data.currentGroup)
        : of(null);
    }),
    map((habits) => ({ type: SET_GROUP_USERS_HABITS, data: habits }))
  );

const observeGroupUsersEpic = (
  action$: ActionsObservable<Action<User | null>>
) =>
  action$.ofType(SET_USER).pipe(
    switchMap(({ data }) => {
      return data && data.currentGroup
        ? groupUsers2$(data.currentGroup)
        : of(null);
    }),
    map((users) => ({ type: SET_GROUP_USERS, data: users }))
  );

const logger = (store) => (next) => (action: Action<any>) => {
  console.group(action.type);
  console.info("dispatching", action);
  let result = next(action);
  console.log("next state", store.getState());
  console.groupEnd();
  return result;
};

const reducer = combineReducers({
  auth: user,
  group: groupMeta,
  users: groupUsers,
  habits: groupUsersHabits,
});
const epicMiddleware = createEpicMiddleware();
const store = createStore(reducer, applyMiddleware(epicMiddleware, logger));
epicMiddleware.run(
  combineEpics(observeGroupUsersHabitsEpic, observeGroupUsersEpic)
);

user$
  .pipe(
    tap((user) => {
      console.log("set user");
      store.dispatch({ type: SET_USER, data: user });
    }),
    switchMap((user) => {
      return user && user.currentGroup
        ? groupMeta$(user.currentGroup)
        : of(null);
    }),
    tap((groupMeta) => {
      console.log({ groupMeta });
      store.dispatch({ type: SET_GROUP_META, data: groupMeta });
    })
  )
  .subscribe();

store.subscribe(() => console.log(store.getState()));

const getGroup = (store: any): Group | undefined => store && store.group;

const getHabits = (store: any): Map<Habits> | undefined =>
  store && store.habits;

const getUsers = (
  store: any
): Map<UserInGroup> | undefined => store && store.users;

// TODO: Don't createSelector with arguments passed to selector
const selectUsersWithHabits = createSelector(
  [getUsers, getHabits],
  (users, habits): Map<UserWithHabits> => {
    if (users && habits) {
      return reduce(
        users,
        (acc, user, uid) => {
          const userHabits = habits[uid];
          if (userHabits && userHabits.location) {
            acc[uid] = {
              ...user,
              ...habits[uid],
              uid,
            } as UserWithHabits;
          }
          return acc;
        },
        {}
      );
    } else {
      return {};
    }
  }
);

export { store, getGroup, getHabits, getUsers, selectUsersWithHabits };
