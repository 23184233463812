import React, { useState, FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Login from "../components/Login";
import { signIn, user$ } from "../services/auth";

const LoginView: FunctionComponent = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>();

  useEffect(() => {
    const subscription = user$.subscribe((user) => {
      if (user) {
        history.push("/users");
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  const _LogIn = () => {
    setError(undefined);
    if (email && password) {
      signIn(email, password).catch((e) => {
        console.log(e);
        setError(e.message || "Authentication failed");
      });
    }
  };

  return (
    <Login
      email={email}
      onLoginTap={_LogIn}
      password={password}
      setEmail={setEmail}
      setPassword={setPassword}
      error={error}
    />
  );
};

export default LoginView;
