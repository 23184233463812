import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { List, Icon } from "semantic-ui-react";
import { chain } from "lodash";

import { Map } from "poola-commons/types";

import { LocatedUser } from "../services/group";

type Props = {
  users: Map<LocatedUser>;
  onUserPress?: (uid: string) => void;
  onUserRemove?: (uid: string) => void;
  selected?: string;
};

const UsersList: FunctionComponent<Props> = ({
  users,
  onUserPress = () => undefined,
  onUserRemove = () => Promise.reject(),
  selected,
}) => {
  return (
    <List selection verticalAlign="middle">
      {chain(users)
        .mapValues((user, uid) => ({ ...user, uid }))
        .sortBy("name")
        .map(({ name, uid, location }) => (
          <List.Item active={uid === selected} onClick={() => onUserPress(uid)}>
            <Icon
              size="large"
              verticalAlign="middle"
              color={location ? "teal" : "pink"}
              name="map pin"
            />
            <List.Content>
              <List.Header>{name}</List.Header>
              <List.Description>
                {!location && <span>Onboarding not completed</span>}{" "}
                <a onClick={() => onUserRemove(uid)}>remove</a>
              </List.Description>
            </List.Content>
          </List.Item>
        ))
        .value()}
    </List>
  );
};

enum State {
  "BASE",
  "ERROR",
  "PROGRESS",
}

type ButtonProps = {
  onClick: () => Promise<any>;
};

const StatefulButton: FunctionComponent<ButtonProps> = ({
  children,
  onClick,
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [state, setState] = useState<State>(State.BASE);

  useEffect(() => {
    const oldTimeout = timeoutRef.current;
    if (oldTimeout) {
      clearTimeout(oldTimeout);
    }
    if (state === State.ERROR) {
      const timeout = setTimeout(() => {
        setState(State.BASE);
      }, 2000);
      timeoutRef.current = timeout;
    }
  }, [state]);

  useEffect(() => {
    const oldTimeout = timeoutRef.current;
    if (oldTimeout) {
      clearTimeout(oldTimeout);
    }
  }, []);

  const stateIcon = () => {
    if (state === State.ERROR) {
      return <Icon color="pink" name="ban" />;
    } else if (state === State.PROGRESS) {
      return <Icon loading color="teal" name="sync" />;
    } else {
      return null;
    }
  };

  return (
    <a onClick={onClick}>
      {stateIcon()}
      {children}
    </a>
  );
};

export default UsersList;
