import map from "lodash/map";

import { Map } from "poola-commons/types";

import { firebaseApp } from "./firebase";
import { API_URL } from "../modules/config";

const getAuthToken = async (): Promise<string> => {
  const user = firebaseApp.auth().currentUser;

  if (user) {
    return await user.getIdToken();
  }

  return Promise.reject({
    status: 401,
    code: "api/unauthorized",
    message: "User is not authorized",
  });
};

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const createAuthHeaders = async () => ({
  ...headers,
  Authorization: `Bearer ${await getAuthToken()}`,
});

const mapResponseOrReject = async <T>(response: Response): Promise<T> => {
  if (response.ok) {
    return (await response.json()) as T;
  }

  const { status, statusText } = response;

  return Promise.reject({
    status: status,
    code: "api/unknown",
    message: statusText || `Error ${status}`,
  });
};

const stringify = (params?: Map<any>): string => {
  if (params) {
    const mapped = map(
      params,
      (value: any, key: string) => `${key}=${value}`
    ).join("&");

    return `?${mapped}`;
  }

  return "";
};

const call = async <T>(
  endpoint: string,
  body: any,
  method: string,
  headers: Map<string> = {}
): Promise<T> =>
  fetch(`${API_URL}${endpoint}`, {
    headers: {
      ...(await createAuthHeaders()),
      ...headers,
    },
    body: JSON.stringify(body),
    method,
  }).then((response) => mapResponseOrReject<T>(response));

export const post = async <T>(
  endpoint: string,
  body: any,
  headers: Map<string> = {}
): Promise<T> => call(endpoint, body, "POST", headers);

export const put = async <T>(endpoint: string, body: any): Promise<T> =>
  call(endpoint, body, "PUT");

export const get = async <T>(
  endpoint: string,
  params?: Map<any>,
  baseUrl: string = API_URL,
  authorized: boolean = true
): Promise<T> =>
  fetch(`${baseUrl}${endpoint}${stringify(params)}`, {
    method: "GET",
    ...(authorized && { headers: await createAuthHeaders() }),
  }).then((response) => mapResponseOrReject<T>(response));

// Ugh, delete keyword is reserved
export const remove = async <T>(endpoint: string, body?: any): Promise<T> =>
  call(endpoint, body, "DELETE");
