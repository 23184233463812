import { useState } from "react";

type SourceFunction<S, T> = (t: S) => Promise<T>;

type Settings = {
  clearResultsOnRequest: boolean;
  clearResultsOnError: boolean;
};

const DEFAULT_SETTINGS = {
  clearResultsOnRequest: false,
  clearResultsOnError: true,
};

const useProgress = <S, T, E>(
  sourceFunction: SourceFunction<S, T>,
  settings: Partial<Settings> = {}
) => {
  const { clearResultsOnRequest, clearResultsOnError } = {
    ...DEFAULT_SETTINGS,
    ...settings,
  };
  const [inProgress, setInProgress] = useState<boolean>();
  const [error, setError] = useState<E>();
  const [result, setResult] = useState<T>();

  const run = (s: S) => {
    if (error) {
      setError(undefined);
    }
    if (result && clearResultsOnRequest) {
      setResult(undefined);
    }
    setInProgress(true);
    return sourceFunction(s)
      .then((newResult) => {
        setResult(newResult);
      })
      .catch((newError: E) => {
        setError(newError);
        if (result && clearResultsOnError) {
          setResult(undefined);
        }
      })
      .then(() => {
        setInProgress(false);
      });
  };
  return [run, result, inProgress, error];
};

export { useProgress };
