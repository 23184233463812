import React, { FunctionComponent } from "react";
import { Map, TileLayer, ScaleControl } from "react-leaflet";

import { Coordinate } from "poola-commons/types";

import useWindowDimensions from "./useWindowDimensions";

type Props = {
    focus: Coordinate;
    zoom: number;
    onClick?: (coordinate: Coordinate) => void;
};

const MapView: FunctionComponent<Props> = ({
    children,
    focus,
    zoom,
    onClick
}) => {
    const { height } = useWindowDimensions();

    return (
        <>
            <div style={{ position: "relative" }}>
                <Map
                    center={focus}
                    zoom={zoom}
                    style={{ height }}
                    onClick={({ latlng: { lat, lng } }) => {
                        if (onClick) {
                            onClick([lat, lng]);
                        }
                    }}
                >
                    <TileLayer url="https://a.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png@2x" />
                    {children}
                    <ScaleControl />
                </Map>
            </div>
        </>
    );
};
export default MapView;
