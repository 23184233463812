import React, { FunctionComponent } from "react";
import { Marker, MarkerProps } from "react-leaflet";
import { divIcon } from "leaflet";

const markerHtmlStyles = ({
  color,
  borderColor,
}: {
  color: string;
  borderColor?: string;
}) => `
  background-color: ${color};
  width: 3rem;
  height: 3rem;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  border-radius: 3rem 3rem 3rem 1rem;
  border: 2px solid ${borderColor || "#ffffff"}`;

const icon = (params: { color: string; borderColor?: string }) =>
  divIcon({
    className: "color-marker",
    iconAnchor: [-12, 24],
    // labelAnchor: [-6, 0],
    popupAnchor: [12, -36],
    html: `<span style="${markerHtmlStyles(params)}"/>`,
  });

const ColorMarker: FunctionComponent<
  {
    color: string;
    borderColor?: string;
  } & MarkerProps
> = (props) => {
  const { color, borderColor, ...rest } = props;
  return <Marker {...rest} icon={icon({ color, borderColor })} />;
};

export default ColorMarker;
