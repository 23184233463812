import { Observable, combineLatest } from "rxjs";
import { switchMap, map as rxMap } from "rxjs/operators";
import map from "lodash/map";
import reduce from "lodash/reduce";

import utils, { observeVal, usersInGroupPath } from "poola-commons/db";
import {
  Coordinate,
  Map,
  HabitsData,
  CreateUsersRequest,
} from "poola-commons/types";
import { post, remove } from "./api";
import { DeleteUsersRequest, DeleteUsersResponse } from "poola-commons";

export type LocatedUser = { location?: Coordinate; name: string };

const groupUsers2$ = (
  group: string
): Observable<Map<{ role: string; name: string }>> => {
  return observeVal<Map<{ name: string; role: string }>>(
    utils.ref().child(usersInGroupPath(group))
  );
};

const groupUsers$ = (group: string): Observable<Map<LocatedUser>> => {
  return observeVal<Map<{ name: string; role: string }>>(
    utils.ref().child(`grp/${group}/users/`)
  ).pipe(
    switchMap((users) =>
      combineLatest(
        map(users, (user, uid) =>
          observeVal<Coordinate>(
            utils.ref().child(`grp/${group}/habits/${uid}/location`)
          ).pipe(rxMap((location) => ({ ...user, location, uid })))
        )
      )
    ),
    rxMap((users) =>
      reduce(
        users,
        (acc, { uid, location, name }) => {
          acc[uid] = { location, name };
          return acc;
        },
        {} as Map<LocatedUser>
      )
    )
  );
};

const inviteUsers = (request: CreateUsersRequest) => post(`/users`, request);

const removeUsers = (request: DeleteUsersRequest) =>
  remove<DeleteUsersResponse>(`/users`, request);

const removeUser = ({
  uid,
  group,
}: {
  uid: string;
  group: string;
}): Promise<{ uid: string; result: boolean }> =>
  remove<DeleteUsersResponse>(`/users`, { uids: [uid], group }).then(
    ({ results }) => results.find(({ uid }) => uid)!
  );

const addUser = ({
  group,
  uid,
  name,
  habits,
}: {
  group: string;
  uid: string;
  name: string;
  habits: HabitsData;
}) => {
  const update = { [`users/${uid}`]: { name }, [`habits/${uid}`]: habits };

  return utils.update(`grp/${group}`, update);
};

export { groupUsers$, addUser, groupUsers2$, inviteUsers, removeUsers, removeUser };
